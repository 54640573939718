@import "../../ListColorsUsedSite/colors.scss";


@mixin fl-btwn-centr {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin fl-concetr-centr {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin fl-clum {
	display: flex;
	flex-direction: column;
}

@mixin fl-btw {
	display: flex;
	justify-content: space-between;
}

@mixin fl-Alcentr {
	display: flex;
	align-items: center;
}

//  header section default style start 
#header {
	background-color: $nav-bg-color;
	color: $nav-color;
	position: sticky;
	top: 0;
	left: 0;
	z-index: 7;

	.container {
		width: calc(100% - 40px);
		padding: 0;

		header {
			@include fl-btw();
			height: 7rem;

			.hamburger-menu {
				display: none
			}

			.nav-logo {
				@include fl-Alcentr();
			}

			nav {
				display: flex;

				.pages-list {
					display: flex;

					li {
						@include fl-Alcentr();

						a {
							margin: 0 1.2rem;
							color: $nav-color;
							font-size: 1.1rem;
							font-weight: 400;

							&:hover {
								color: red;
							}
						}
					}

					li.federation {
						.drop-down-menu {
							display: none;
							position: absolute;
							top: 100%;
							background-color: $nav-bg-color;
							transition: 1s ease-in;
							padding-bottom: 1rem;

							a {
								padding: 0.4rem 0;
								display: block;

							}
						}

						&:hover {
							.drop-down-menu {
								display: block;
								transition: 1s ease-in;

							}
						}
					}

				}
			}

			.languages {
				@include fl-btwn-centr();

				.active-lang {
					margin: 0 0.4rem;
					color: black;
					cursor: pointer;
					border-bottom: 1px solid red;
					color: red;
				}

				.noActive-lang {
					margin: 0 0.4rem;
					color: black;
					cursor: pointer;
				}
			}
		}

		.hamburger-menu-list {
			display: none;


		}
	}
}


//============== header section responsive start 1050px  ======================
@media screen and (max-width: 1050px) {
	#header {
		.container {
			width: calc(100% - 20px);

			header {
				.hamburger-menu {
					display: flex;
					align-items: center;
					font-size: 4rem;
					cursor: pointer;

					span {
						height: 7rem;
						line-height: 6rem;

						svg:not(:last-child) {
							margin-right: -15px;
						}

						svg {
							font-size: 2rem;
						}

					}
				}

				.nav-logo {
					@include fl-Alcentr();
				}

				nav {
					display: none;
				}

				.languages {
					display: none;
				}
			}

			.hamburger-menu-list {
				display: flex;
				flex-direction: column;
				align-items: center;
				position: fixed;
				padding-top: 2rem;
				top: 7.3rem;
				right: 0;
				width: 40%;
				height: 40rem;
				background-color: $hamburgerMenuBgColor;
				transition: .5s ease;

				.pages-list {
					li {
						margin: .7rem;

						a {
							color: $nav-color;
							font-size: 1.5rem;
							font-weight: 400;
						}
					}
				}

				.languages-mobil {
					display: flex;

					li {
						margin: 1rem .6rem;
						font-size: 1.5rem;
						cursor: pointer;
					}

					.active-lang {
						border-bottom: 1px solid red;
						color: red;
					}
				}
			}

			.noActive {
				right: -100%;
			}
		}
	}
}