@import "../../ListColorsUsedSite/colors.scss";

@mixin fl-btwn-centr {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin fl-concetr-centr {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin fl-clum {
	display: flex;
	flex-direction: column;
}

@mixin fl-btw {
	display: flex;
	justify-content: space-between;
}

@mixin fl-Alcentr {
	display: flex;
	align-items: center;
}

//======  news section default style =========   
#news {
	color: $newsSectionWriteColor;

	.news-animation {
		background-color: #EF3340;
		height: 25rem;

		.title-animation {
			background: url("../../assets/images/patternn.svg") top right / 50% no-repeat;
			height: 25rem;

			h2 {
				font-size: 9rem;
				font-weight: 700;
				letter-spacing: 5px;
				padding: 5rem 0 0 8rem;
			}

			.animation-image {
				height: 14rem;
				position: absolute;
				top: 9rem;
				right: 4rem;
				animation-name: newsAnimationWeb;
				animation-duration: 2s;
				animation-iteration-count: 1;
				animation-timing-function: linear;
				animation-direction: alternate;
			}
		}
	}
}

.container {
	.tr-all-news {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
		justify-content: center;
		justify-items: center;
		grid-gap: 2rem;

		.news {
			color: white;
			margin-bottom: 1rem;
			position: relative;
			cursor: pointer;

			.newsImg {
				width: 100%;
				height: 19rem;
				object-fit: cover;
				filter: grayscale(100%);
			}

			&:hover img {
				filter: grayscale(0);
				transition: filter 0.5s ease;
			}

			.newsMiniInfo {
				padding: 0 1rem;

				h4 {
					font-size: 1.4rem;
					font-weight: 500;
					margin-top: 2rem;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				p {
					font-size: 1.2rem;
					font-weight: 500;
					margin: 1.5rem 0 2rem;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				span {
					font-size: 1.3rem;
					font-weight: 600;
					text-decoration: underline;
					color: white;
				}
			}
		}
	}
}


.pagination {
	display: flex;
	flex-wrap: wrap;
	gap: .5rem;
	margin-top: 5rem;
	justify-content: center;
}

.pagination>li {
	cursor: pointer;
}

.pagination>li>a {
	padding: 0.5rem 1rem;
	background-color: red;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.pagination>li.active>a {
	background-color: black;
	color: white;
}

@keyframes newsAnimationWeb {
	0% {
		right: 0;
	}

	100% {
		right: 4rem;
	}
}

//======  news section 1050px responsive style =========
@media screen and (max-width: 1050px) {
	#news {
		.news-animation {
			height: 23rem;
			background-image: url("../../assets/images/patternn.svg");
			background-repeat: no-repeat;
			background-size: cover;
			background-position-y: 7rem;

			.title-animation {
				display: flex;
				justify-content: center;
				position: relative;
				left: 50%;
				transform: translate(-50%);
				background: none;

				h2 {
					font-size: 4rem;
					padding: 0;
				}

				.animation-image {
					transform: rotate(90deg);
					height: 5rem;
					position: absolute;
					z-index: -1;
					top: 11rem;
					right: inherit;
					animation-name: newsAnimationMobile;
					animation-duration: 2s;
					animation-timing-function: linear;
					animation-iteration-count: 1;
					animation-direction: alternate;
				}
			}
		}
	}

}



@media screen and (max-width: 622px) {
	#news {
		.container {
			.tr-all-news {
				.news {
					.newsMiniInfo {
						text-align: center;
					}
				}
			}
		}
	}
}

@keyframes newsAnimationMobile {
	0% {
		top: 11rem;
	}

	100% {
		top: 1rem;
	}
}