:root {
	--main-color: #1B3667;
	--loader-color: #fff;
	--back-color: #0f0f0f;
	--time: 2s;
	--size: 3px;
}

.loader {
	background-color: var(--main-color);
	overflow: hidden;
	width: 100vw;
	width: 100dvw;
	height: 100vh;   
	height: 100dvh;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: flex-start;
	z-index: 100000;
}

.loader__element {
	height: var(--size);
	width: 40%;
	margin: 0 auto;
	background: var(--back-color);

}

.loading-img {
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.loader__element:before {
	content: '';
	display: block;
	background-color: var(--loader-color);
	height: var(--size);
	width: 0;
	animation: getWidth var(--time) ease-in infinite;
}

@keyframes getWidth {
	100% {
		width: 100%;
	}
}