@import "../../ListColorsUsedSite/colors.scss";


@mixin fl-btwn-centr {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin fl-concetr-centr {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin fl-clum {
	display: flex;
	flex-direction: column;
}

@mixin fl-btw {
	display: flex;
	justify-content: space-between;
}

@mixin fl-Alcentr {
	display: flex;
	align-items: center;
}

// === multimedia section default style start ====
#section-multimedia {
	color: $MultimediaWriteColor;

	.container {
		h2 {
			font-size: 4rem;
			font-weight: 700;
			word-spacing: 1rem;
			text-align: center;
			margin: 2rem 0;
		}

		.multimedia {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
			justify-content: center;
			justify-items: center;
			grid-gap: 2rem;

			.tr-media {
				width: 100%;
				position: relative;

				img {
					width: 100%;
					object-fit: cover;
					filter: grayscale(100%);
					aspect-ratio: 4 / 3;
				}

				.overlay {
					display: block;
					color: white;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					background-color: $MultimediaHoverColor;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					text-align: center;
					opacity: 0;
					transition: 1s linear;
					cursor: pointer;

					h5 {
						font-size: 1.5rem;
						font-weight: 500;
						margin: 1rem 0;
					}

					span {
						font-size: 1.2rem;
						font-weight: 500;
						text-decoration-line: underline;
						color: white;
					}
				}

				&:hover .overlay {
					opacity: 1;
				}
			}
		}
	}
}


// === multimedia section resopnsive  style start ====
@media screen and (max-width: 1050px) {
	#section-multimedia {
		.container {
			width: 95%;

			.multimedia {
				grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));

				.tr-media {
					position: inherit;

					.overlay {
						height: auto;
						position: inherit;
						background-color: transparent;
						text-align: center;
						opacity: 1;

						h5 {
							margin-top: 2rem;
						}

						span {
							display: inline-block;
							margin-bottom: 2rem;
						}
					}
				}
			}
		}
	}
}