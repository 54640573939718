@import "../../ListColorsUsedSite/colors.scss";

@mixin fl-btwn-centr {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin fl-concetr-centr {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin fl-clum {
	display: flex;
	flex-direction: column;
}

@mixin fl-btw {
	display: flex;
	justify-content: space-between;
}

@mixin fl-Alcentr {
	display: flex;
	align-items: center;
}

//=== managment and stuff component default style ====
.cart {
	position: relative;

	img {
		width: 437px;
		height: 423px;
		object-fit: cover;
	}

	.datas {
		width: 437px;
		height: 423px;
		position: absolute;
		top: 0;
		left: 0;
		background-color: $managmentAndStuffHoverBgColor;
		opacity: 0;
		padding: 2rem;

		&:hover {
			opacity: 1;
			transition: .5s ease;
			cursor: pointer;
		}

		h5 {
			font-size: 1.5rem;
			font-weight: 500;
			margin-bottom: 1rem;
		}

		p {
			font-size: 1.3rem;
			font-weight: 400;
		}
	}
}

//=== managment and stuff component default style ====

@media screen and (max-width:1050px) {
	.cart {
		img {
			width: 310px;
			height: 310px;
		}

		.datas {
			width: 310px;
			height: auto;
			position: inherit;
			background-color: transparent;
			opacity: 1;
			padding: 2rem;
			text-align: center;

		}
	}
}