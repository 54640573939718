@import "../../ListColorsUsedSite/colors.scss";

@mixin fl-btwn-centr {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin fl-concetr-centr {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin fl-clum {
	display: flex;
	flex-direction: column;
}

@mixin fl-btw {
	display: flex;
	justify-content: space-between;
}

@mixin fl-Alcentr {
	display: flex;
	align-items: center;
}

// start-history section default style start
#start-history-section {
	margin-top: 7rem;

	.container {
		.start-history {
			display: flex;
			gap: 3rem;

			.start {
				color: $startSectinColor;
				flex: 1;

				img {
					width: 100%;
					object-fit: cover;
				}

				.info {
					margin-top: 30px;

					h4 {
						font-size: 5rem;
						font-weight: 700;
					}

					p {
						width: 33rem;
						font-size: 2rem;
						font-weight: 400;
					}
				}
			}

			.history {
				flex: 1;
				position: relative;

				img {
					width: 100%;
					object-fit: cover;
				}

				.info {
					position: absolute;
					top: 50%;
					left: 25%;
					transform: translate(-25%, -50%);

					h4 {
						font-size: 5rem;
						font-weight: 700;
						color: $historySectin-h4-Color;
					}

					p {
						font-size: 2rem;
						font-weight: 400;
						color: $historySectin-p-Color;
					}
				}
			}
		}
	}
}

// start-history section  responsive 1510px 
@media screen and (max-width: 1510px) {
	#start-history-section {

		.container {
			.start-history {
				.start {
					.info {
						h4 {
							font-size: 3rem;
						}

						p {
							font-size: 1.5rem;
						}
					}
				}

				.history {
					.info {
						h4 {
							font-size: 3rem;
						}

						p {
							font-size: 1.5rem;
						}
					}
				}
			}
		}
	}
}

// start-history section  responsive 1050px 
@media screen and (max-width: 1050px) {
	#start-history-section {
		margin-top: 0;

		.container {
			.start-history {
				flex-direction: column;

				.start {
					.info {
						margin: 4rem 0 2rem;
						text-align: center;

						h4 {

							font-size: 2.2rem;
						}

						p {
							width: 25rem;
							margin: 1.5rem auto;
							font-size: 1.5rem;
						}
					}
				}

				.history {
					.info {
						top: 30%;
						left: 50%;
						transform: translateX(-50%);
						text-align: center;

						h4 {
							font-size: 2.2rem;
						}

						p {
							font-size: 1.5rem;
						}
					}
				}
			}
		}
	}
}