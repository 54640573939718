@import "../../ListColorsUsedSite/colors.scss";

@mixin fl-btwn-centr {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin fl-concetr-centr {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin fl-clum {
	display: flex;
	flex-direction: column;
}

@mixin fl-btw {
	display: flex;
	justify-content: space-between;
}

@mixin fl-Alcentr {
	display: flex;
	align-items: center;
}

// === contact page default style start ===    
#contact {
	color: $contactPageWriteColor;
	min-height: calc(100vh - 15rem);
	display: flex;
	justify-content: center;
	align-items: center;

	.container {
		width: 95%;

		.map-form {
			display: grid;
			grid-template-columns: (repeat(auto-fit, minmax(20rem, 1fr)));
			grid-gap: 1rem;
			justify-content: center;
			align-items: center;

			.map {
				height: 33rem;

				.leaflet-control-attribution {
					display: none;
				}
			}

			.form-group {
				text-align: center;

				h2 {
					font-size: 4rem;
					font-weight: 700;
					margin-bottom: 2rem;
				}

				form {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				input {
					width: 100%;
					padding: 0.75rem 1rem;
					margin-bottom: 2rem;
					font-size: 1.25rem;
					outline: none;
					border: none;
				}

				.error {
					color: $inpurErrorMessageColor;
					margin-top: -1.4rem;
					margin-bottom: .6rem;
					padding-left: .3rem;
					font-size: 1.2rem;
					font-weight: 500;
				}

				.error-textarea {
					margin-top: 0;
				}

				textarea {
					width: 100%;
					font-size: 1.5rem;
					outline: none;
					border: none;
					padding: .75rem;
					resize: none;
				}

				button {
					display: block;
					padding: 1rem 4rem;
				}
			}
		}
	}
}


// number (telefon) inputun css
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	display: none;
}