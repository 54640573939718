@import "../../ListColorsUsedSite/colors.scss";

@mixin fl-btwn-centr {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin fl-concetr-centr {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin fl-clum {
	display: flex;
	flex-direction: column;
}

@mixin fl-btw {
	display: flex;
	justify-content: space-between;
}

@mixin fl-Alcentr {
	display: flex;
	align-items: center;
}

// =====   slider section default style start  ======
#slider {
	position: relative;
	width: 100%;
	height: 75vh;
	color: $sliderWriteColor;

	.video-background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.container {
		padding: 0;

		.swiper {
			height: 75vh;
		}

		.swiper-button-prev,
		.swiper-button-next {
			background-color: $sliderPrevNextButonBgColor;
			color: $sliderPrevNextButonColor;
			padding: 2rem;
		}

		.swiper-wrapper {
			left: 8rem;
			height: 88%;
		}

		.swiper-slide {
			top: 30%;
		}

		.swiper-slide-active {
			animation-name: MoveTextBtn;
			animation-duration: 2.5s;
			animation-iteration-count: 1;
			animation-direction: alternate;
			animation-timing-function: linear;

			.date {
				font-size: 5rem;
				font-weight: 700;
				position: absolute;
				top: 5%;
			}

			.text-btn {
				position: absolute;
				top: 22%;
				transform: translateX(-2%);
			}

			.text {
				font-size: 3rem;
				font-weight: 400;
			}

			a {
				display: inline-block;
				margin-top: 1rem;
				padding: 1.5rem 2rem;
				border: none;
				font-size: 1.5rem;
				font-weight: 400;
				cursor: pointer;
				background-color: $redButtonBG-color;
				color: $redButton-color;
			}

		}

		.autoplay-progress {
			position: absolute;
			width: 50%;
			top: 94%;
			transform: translateX(-50%);
			left: 50%;
		}

		.line {
			height: .3rem;
			border-radius: 2rem;
			background-color: $sliderLineColor;
		}

		.swiper-pagination {
			position: relative;
			font-size: 1.5rem;
		}
	}
}


@keyframes MoveTextBtn {
	0% {
		top: 170%;
	}

	100% {
		top: 30%;
	}

}

// =====   slider section responsive start  =======

@media screen and (max-width: 1050px) {
	#slider {
		display: none;
	}
}