@import "../../ListColorsUsedSite/colors.scss";

@mixin fl-btwn-centr {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin fl-concetr-centr {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin fl-clum {
	display: flex;
	flex-direction: column;
}

@mixin fl-btw {
	display: flex;
	justify-content: space-between;
}

@mixin fl-Alcentr {
	display: flex;
	align-items: center;
}

// === registration page default style start ===    
#registration {
	color: $contactPageWriteColor;
	height: calc(100vh - 257px);

	.container {
		.form-group {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			// h2 {
			// 	font-size: 3rem;
			// 	font-weight: 700;
			// 	margin-bottom: 2rem; 
			// }
			.register{
								font-size: 2rem;
									font-weight: 600;
									margin-bottom: 2rem;
			}

			form {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 40%;
			}

			input {
				font-family: 'Roboto', sans-serif;
				width: 100%;
				padding: 0.75rem 1rem;
				margin-bottom: 2rem;
				font-size: 1.4rem;
				outline: none;
				border: none;
			}

			.dateInput {
				color: #838383;
			}

			.error {
				color: $inpurErrorMessageColor;
				margin-top: -1.4rem;
				margin-bottom: .6rem;
				padding-left: .3rem;
				font-size: 1.2rem;
				font-weight: 500;
			}

			.error-textarea {
				margin-top: 0;
			}

			textarea {
				width: 100%;
				font-size: 1.5rem;
				outline: none;
				border: none;
				padding: .75rem;
				resize: none;
				font-family: 'Roboto', sans-serif;
			}

			button {
				width: 100%;
				display: block;
				padding: 1rem 4rem;
			}
		}
	}
}

//  registration section responsive 2500px style start
@media screen and (max-width: 2500px) {
	#registration {
		height: auto;

		.container {
			.form-group {
				height: 100vh;
			}
		}
	}
}

@media screen and (max-width: 1500px) {
	#registration {
		height: auto;

		.container {
			.form-group {
				height: auto;
			}
		}
	}
}

//  registration section responsive 800px style start
@media screen and (max-width: 800px) {
	#registration {
		.container {
			.form-group {
				// .registr-title {
				// 	font-size: 1.8rem;
				// }
								.register {
									text-align: center;
								}

				form {
					width: 80%;
				}

				input[type="date"] {
					-webkit-appearance: none;
					height: 36px;
					color: #838383;
				}

				.dateInput::before {
					content: attr(placeholder);
					color: #838383;
					width: 230px;
				}

				.noWidth::before {
					width: 0;
				}
			}
		}
	}
}


// number (telefon) inputun css
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	display: none;
}