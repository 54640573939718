@import "../../ListColorsUsedSite/colors.scss";

@mixin fl-btwn-centr {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin fl-concetr-centr {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin fl-clum {
	display: flex;
	flex-direction: column;
}

@mixin fl-btw {
	display: flex;
	justify-content: space-between;
}

@mixin fl-Alcentr {
	display: flex;
	align-items: center;
}

// how to start section style start
#how-to-start-section {
	color: $howToStartColor;

	.container {
		.how-to-start {
			@include fl-btw();

			img.howToStartImage {
				width: 45%;
				margin-top: 5rem;
				object-fit: cover;
				object-position: right;
			}

			.what-is-triathlon {
				width: 45%;

				h2 {
					font-size: 4rem;
					font-weight: 700;
					margin-bottom: 1rem;
				}

				p {
					font-size: 1.2rem;
					font-weight: 400;
					margin: 1rem 0;
				}

				h5 {
					margin-top: 7rem;
					font-size: 1.5rem;
				}

				.initial-popular-format {
					@include fl-btw();
					flex-wrap: wrap;

					.initial-popular-format-info {
						width: 50%;
					}
				}
			}
		}
	}
}

// how to start section Responsive start
@media screen and (max-width: 1050px) {
	#how-to-start-section {
		background: none;
		background-color: $BgColorBlue;
		text-align: center;

		.container {
			width: 90%;

			.how-to-start {
				img.howToStartImage {
					display: none;
				}

				.what-is-triathlon {
					width: 100%;

					p {
						width: 80%;
						margin: 1rem auto;
						text-align: start;
					}
				}
			}
		}
	}
}