#executiveBoard {
	max-width: 894px;
	margin: 0 auto;

	h2 {
		font-size: 4rem;
		font-weight: 700;
		text-align: center;
		margin: 3rem 0;
	}

	.tr-executiveBoard {
		max-width: 894px;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(437px, 1fr));
		justify-items: center;
		gap: 20px;
	}
}

@media screen and (max-width:1050px) {
	#executiveBoard {
		max-width: 640px;

		.tr-executiveBoard {
			grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
		}
	}

}