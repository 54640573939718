@import "../../ListColorsUsedSite/colors.scss";

@mixin fl-btwn-centr {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin fl-concetr-centr {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin fl-clum {
	display: flex;
	flex-direction: column;
}

@mixin fl-btw {
	display: flex;
	justify-content: space-between;
}

@mixin fl-Alcentr {
	display: flex;
	align-items: center;
}

// === federation section dafault style satart
#federation {
	color: $FederationWriteColor;

	.rotate-btn-component {
		display: none;
	}

	.federation-animation {
		background: url("../../assets/images/patternn.svg") top right / 50% no-repeat;
		background-color: #EF3340;

		.title-animation {
			@include fl-btwn-centr();
			padding: 0;
			height: 30rem;

			h2 {
				font-size: 9rem;
				font-weight: 700;
				letter-spacing: 5px;
				padding: 5rem 0 0 8rem;
			}

			.animation-image {
				height: 30rem;
				position: absolute;
				top: 7rem;
				right: 8rem;
				animation-name: FederationAnimation;
				animation-duration: 3s;
				animation-iteration-count: 1;
				animation-timing-function: linear;
				animation-direction: alternate;
			}
		}
	}

	.tr-about-federation {
		margin-top: 5rem;

		h2 {
			font-size: 4rem;
			font-weight: 700;
			word-spacing: 1rem;
			text-align: center;
			margin-top: 2rem;
		}

		.container {
			.tr-federation {
				display: grid;
				grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
				column-gap: 2rem;

				.federation-presidents {
					.about-presidents {
						position: relative;
						aspect-ratio: 1/1;

						.overlay {
							width: 100%;
							height: 100%;
							background-color: $FederationAboutHoverColor;
							position: absolute;
							top: 0;
							left: 0;
							opacity: 0;
							transition: .5s ease;
							cursor: pointer;
							@include fl-Alcentr();
							flex-direction: column;
							justify-content: center;

							h5 {
								font-size: 1.5rem;
								font-weight: 500;
								margin-top: 2rem;
							}

							h6 {
								font-size: 1rem;
								font-weight: 400;
								text-align: center;
							}
						}

						&:hover .overlay {
							opacity: 1;
							color: white;
						}

						img {
							height: 100%;
							width: 100%;
							object-fit: cover;
						}
					}
				}

				.about-federation {
					margin: 2rem 0 7rem;
					font-size: 1rem;
					font-weight: 400;
					white-space: pre-line;
					text-align: start;
				}
			}
		}
	}

	#report {
		padding: 5rem 0;
		display: flex;
		align-items: center;
		justify-content: center;

		.date-annual {
			margin-right: 4rem;

			.date {
				font-size: 4rem;
				font-weight: 700;
			}

			.annual {
				font-size: 2rem;
				font-weight: 400;
			}
		}

		a {
			padding: 1.5rem 2rem;
			border: none;
			font-size: 1.5rem;
			font-weight: 400;
			cursor: pointer;
			background-color: $redButtonBG-color;
			color: $redButton-color;
		}
	}

	#clubs {
		height: 8rem;
		margin: 5rem 0 10rem;

		h2 {
			font-size: 4rem;
			font-weight: 700;
			text-align: center;
			margin-bottom: 3rem;
		}

		.container {
			padding-top: 0;
		}
	}

}

@media screen and (max-width:1050px) {
	#federation {
		.rotate-btn-component {
			display: block;
		}

		.federation-animation {
			background-color: #EF3340;
			background-image: url("../../assets/images/patternn.svg");
			background-repeat: no-repeat;
			background-size: cover;
			background-position-y: 7rem;

			.title-animation {
				flex-direction: column;
				height: 25rem;
				background-image: url("../../assets/images/patternn.svg");
				background-repeat: no-repeat;
				background-size: cover;
				background-position-y: 7rem;

				h2 {
					font-size: 4rem;
					letter-spacing: 2px;
					margin-top: 2rem;
					padding: 0;
				}

				.animation-image {
					height: 18rem;
					width: 20rem;
					position: absolute;
					top: 14rem;
				}
			}

		}

		.tr-about-federation {
			margin-top: 1rem;

			.container {
				width: 95%;
				padding-top: 0;

				.tr-federation {
					display: flex;
					flex-direction: column-reverse;

					.federation-presidents {
						display: flex;
						flex-direction: column;

						.about-presidents {
							aspect-ratio: 0;

							.overlay {
								position: inherit;
								opacity: 1;
								background-color: transparent;

								h5 {
									font-size: 2rem;
								}

								h6 {
									font-size: 1.5rem;
								}
							}

							img {
								height: 100%;
								width: 100%;
								object-fit: cover;
							}
						}

						.about-presidents:nth-child(3) {
							img {
								aspect-ratio: 1;
							}
						}
					}

				}
			}
		}

		#managment,
		#stuff {
			padding: 0;

			.tr-managments,
			.tr-stuff {
				width: 95%;
			}
		}

		#report {
			flex-direction: column;
			text-align: center;

			.date-annual {
				margin: 0 0 2rem;
			}

		}

		#clubs {
			.container {
				width: 95%;
			}
		}

	}
}

@keyframes FederationAnimation {
	0% {
		right: 0;
	}

	100% {
		right: 8rem;
	}
}