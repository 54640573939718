body {
    font-family: "Roboto", sans-serif;
    background: #1b3667 url("./assets/images/AllBGpattern.svg") top left / 51% no-repeat repeat fixed;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
li {
    list-style: none;
}
a {
    text-decoration: none;
}
.text-uppercase {
    text-transform: uppercase;
}

.container {
    max-width: 1500px;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 22px;
}
.min-container {
    width: calc(100% - 300px);
    margin: 0 auto;
}

/* web site font-size responsive */
@media screen and (max-width: 1500px) {
    .container {
        max-width: 95%;
    }
}
@media screen and (max-width: 1050px) {
    body {
        background-size: 100%;
        background: #1b3667;
    }
}

@media screen and (max-width: 1420px) {
    html {
        font-size: 14px;
    }
}

@media screen and (max-width: 1260px) {
    html {
        font-size: 11px;
    }
}
