@import "../../ListColorsUsedSite/colors.scss";

@mixin fl-btwn-centr {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin fl-concetr-centr {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin fl-clum {
	display: flex;
	flex-direction: column;
}

@mixin fl-btw {
	display: flex;
	justify-content: space-between;
}

@mixin fl-Alcentr {
	display: flex;
	align-items: center;
}

//  tr-history section default style start
#tr-history {
	color: $historyColor;

	.container {
		.history {
			.top-history {
				@include fl-btw();
				margin-bottom: 2rem;
				display: flex;
				gap: 2rem;
				margin-bottom: 3rem;

				img {
					width: 47%;
					margin-top: 6rem;
					flex: 1;
					object-fit: cover;
					margin-top: 7rem;
				}

				.tr-about-history {
					width: 47%;
					text-align: start;
					padding-left: 4rem;
					flex: 1;

					h2 {
						margin-bottom: 2rem;
						font-size: 4rem;
						font-weight: 700;
					}

					p {
						font-size: 1.3rem;
						line-height: 1.7rem;
						font-weight: 400;
						margin: 1.7rem auto;

					}
				}
			}

			.bottom-history {
				@include fl-btw();
				display: flex;
				gap: 2rem;

				img {
					width: 47%;
					flex: 1;
					object-fit: cover;
				}
			}
		}

	}
}


//  tr-history section responsive 1050px style start
@media screen and (max-width: 1050px) {
	#tr-history {
		.container {
			width: 95%;

			.history {
				.top-history {
					flex-direction: column-reverse;
					margin-bottom: 2rem;

					img {
						margin-top: 0;
						width: 100%;
					}

					.tr-about-history {
						width: 100%;
						padding-left: 0;

						h2 {
							text-align: center;
						}
					}
				}

				.bottom-history {
					flex-direction: column;

					img {
						width: 100%;
					}
				}
			}

		}
	}
}