@import "../../ListColorsUsedSite/colors.scss";

@mixin fl-btwn-centr {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin fl-concetr-centr {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin fl-clum {
	display: flex;
	flex-direction: column;
}

@mixin fl-btw {
	display: flex;
	justify-content: space-between;
}

@mixin fl-Alcentr {
	display: flex;
	align-items: center;
}


//  athletes section default style start 
#athletes {
	color: $athletesSectionWriteColor;

	.athletes-header {
		background-color: #EF3340;
		;

		.athletes-image {
			@include fl-btwn-centr();
			padding: 0;
			height: 30rem;
			background: url("../../assets/images/patternn.svg") top right / 45% no-repeat;

			h2 {
				font-size: 9rem;
				font-weight: 700;
				letter-spacing: 5px;
				padding: 5rem 0 0 8rem;
			}

			.image-atletes {
				height: 30rem;
				position: absolute;
				top: 7rem;
				right: 8rem;
				animation-name: veloMove;
				animation-duration: 1.5s;
				animation-direction: alternate;
				animation-iteration-count: 1;
				animation-timing-function: linear;
			}
		}
	}

	.container {
		.tr-athletes {
			@include fl-clum();

			.tr-athlets-OpenClose {
				h2 {
					font-size: 4rem;
					font-weight: 700;
					margin-bottom: 4rem;
					word-spacing: 1.5rem;
					text-align: center;

				}

				.OpenClose {
					display: none;
				}
			}

			.teenage-athletes {
				display: flex;
				justify-content: space-evenly;
				flex-wrap: wrap;
				gap: 1rem;

				.teenage {
					width: 17rem;
					color: white;
					text-align: center;

					img {
						width: 17rem;
						height: 17rem;
						object-fit: cover;
					}

					h6 {
						font-size: 1rem;
						font-weight: 500;
						word-wrap: break-word;
					}

					span {
						margin-bottom: 3rem;
						font-size: 1rem;
						font-weight: 300;
						display: inline-block;
					}
				}
			}

			.young-menWomen-athlete {
				display: flex;
				justify-content: space-evenly;
				flex-wrap: wrap;
				gap: 1rem;

				.young-menWomen {
					display: flex;
					margin-bottom: 5rem;

					img {
						width: 34rem;
						height: 37rem;
						object-fit: cover;
					}

					.young-menWomen-athlete-datas {
						background-color: $youngAthleteDatasBgColor;
						width: 34rem;
						height: 37rem;
						padding: 3rem 2rem;
						margin-left: 2rem;
						color: white;

						h6 {
							margin: 0;
							font-size: 1.5rem;
							font-weight: 500;
						}

						span {
							display: block;
							margin: 2rem 0;
							font-size: 1rem;
							font-weight: 300;
						}

						p {
							white-space: pre-line;
							line-height: 1.7rem;
						}
					}
				}
			}
		}

	}
}

//  athletes section Responsive style start 
@media screen and (max-width:1050px) {
	#athletes {
		.athletes-header {
			background-image: url("../../assets/images/patternn.svg");
			background-repeat: no-repeat;
			background-size: cover;
			background-position-y: 7rem;

			.athletes-image {
				flex-direction: column;
				height: 17rem;
				background: none;

				h2 {
					font-size: 4rem;
					letter-spacing: 2px;
					margin-top: 2rem;
					padding: 0;
				}

				.image-atletes {
					height: 10rem;
					width: 20rem;
					position: absolute;
					top: 14rem;
				}
			}
		}

		.container {
			width: 95%;

			.tr-athletes {
				@include fl-clum();

				.tr-athlets-OpenClose {
					text-align: center;

					h2 {
						font-size: 4rem;
						margin-bottom: 1rem;
					}

					.OpenClose {
						display: block;
						margin-bottom: 5rem;
					}
				}

				.teenage-athletes {
					gap: 1rem;

					.teenage {
						width: 25rem;
						text-align: center;

						img {
							width: 25rem;
							height: 30rem;
						}
					}
				}

				.young-menWomen-athlete {
					width: 95%;

					.young-menWomen {
						color: white;
						flex-direction: column;
						margin-bottom: 0;

						img {
							width: 25rem;
							height: 30rem;
						}

						.young-menWomen-athlete-datas {
							background-color: transparent;
							width: 26rem;
							height: auto;
							padding: 3rem 0;
							margin-left: 0;

							h6 {
								text-align: center;
							}

							span {
								text-align: center;
							}
						}
					}
				}
			}

		}
	}
}

@media screen and (max-width:420px) {
	#athletes {
		.container {
			.tr-athletes {
				.tr-athlets-OpenClose {
					h2 {
						font-size: 2.5rem;
						margin-bottom: 3rem;
					}
				}

			}

		}
	}
}

@keyframes veloMove {
	0% {
		right: 0;
	}

	100% {
		right: 8rem;
	}
}