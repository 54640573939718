@import "../../ListColorsUsedSite/colors.scss";

@mixin fl-btwn-centr {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin fl-concetr-centr {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin fl-clum {
	display: flex;
	flex-direction: column;
}

@mixin fl-btw {
	display: flex;
	justify-content: space-between;
}

@mixin fl-Alcentr {
	display: flex;
	align-items: center;
}

// === result section default style start  ==========
#results {
	min-height: calc(100vh - 15rem);

	.container {
		h2 {
			display: block;
			font-size: 4rem;
			font-weight: 700;
			text-align: center;
			color: white;
			margin-bottom: 2rem;
		}

		.table-mobile {
			display: none;
		}

		.table-web {
			padding: 2rem 1rem;
			background-color: $ResultsSectionTableBgColor ;
			text-align: center;
			margin: 1rem auto 7rem;

			hr {
				width: 98%;
				margin: 0 auto;
			}

			ul {
				display: flex;
				margin-bottom: 1rem;
			}

			.event {
				flex: .5;
			}

			.date {
				flex: 1;
			}

			.individual {
				flex: .5;
			}

			.team {
				flex: .5;
			}

			.table-date {
				display: flex;
				margin: 1.5rem auto;

				h5 {
					margin: 0;
					font-size: 1.2rem;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
				}
			}
		}
	}
}

// === result section Responsive 1050px start  ==========
@media screen and (max-width:1050px) {
	#results {
		.container {
			width: 95%;

			.table-web {
				display: none;
			}

			.table-mobile {
				display: grid;
				grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
				justify-content: center;
				justify-items: center;
				grid-gap: 2rem;

				.table {
					display: flex;
					justify-content: space-between;
					margin-bottom: 3rem;
					padding: 1rem;
					background-color: $ResultsSectionTableBgColor;

					ul {
						li {
							margin: 1rem auto;
						}
					}

					.table-date {
						display: flex;
						flex-direction: column;
						margin-left: 2rem;

						h5 {
							width: 150px;
							margin: 0.95rem auto;
							font-size: 1.2rem;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 1;
							overflow: hidden;
						}

						p {
							margin: 0 auto;
						}

						a {
							margin: 0.6rem auto;

						}
					}
				}
			}
		}
	}
}