@import "../../ListColorsUsedSite/colors.scss";

.rotate-btn {
	background-color: $RotateBtnBgColor;
	color: black;
	width: 4rem;
	height: 3rem;
	text-align: center;
	margin: 2rem auto;

	.rotate-icon {
		font-size: 3rem;
	}
}