h6 {
	font-size: 1.2rem;
	font-weight: 500;
	margin: 2rem 0;
}

.svgIcon-length {
	margin: 2rem 0;

	img {
		width: 2rem;
	}

	span {
		display: inline-block;
		margin-left: 3rem;
		font-size: 2rem;
	}
}

// ======  component responsive start ===
@media screen and (max-width: 1050px) {
	.svgIcon-length {
		margin: 2rem 0;

		img {
			width: 1rem;
		}

		span {
			display: inline-block;
			margin-left: 1.5rem;
			font-size: 1rem;
		}
	}
}