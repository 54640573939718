$nav-bg-color:#D9D9D9;
$nav-color:#1F1F1F;
$hamburgerMenuBgColor:#D9D9D9;
$redButtonBG-color:#DF2935;
$redButton-color:#FFFFFF;
$startSectinColor:#FFFFFF;
$historySectin-h4-Color:#DF2935;
$historySectin-p-Color:#FFFFFF;
$howToStartColor:#FFFFFF;
$historyColor:#FFFFFF;
$tableBgColor:#D9D9D9;
$athletesSectionWriteColor:#FFFFFF;
$letsDoItSectionColor:#FFFFFF;
$sliderWriteColor:#FFFFFF;
$sliderPrevNextButonBgColor:#D9D9D9;
$sliderPrevNextButonColor:#1F1F1F;
$sliderLineColor:#FFFFFF;
// $footerBgColor:#D9D9D9;
$footerBgColor:#C1C1C1;
$footerColor:#1F1F1F; 
$MobilFooterBgColor:#1B3667; 
$BgColorBlue:#0047AB; 
$EventSectionTableBgColor:#D9D9D9;
$ResultsSectionTableBgColor:#D9D9D9;
$athletesSectionWriteColor:#FFFFFF;
$youngAthleteDatasBgColor:#DF2935;
$RotateBtnBgColor:#D9D9D9;
$RotateBtnColor:#1F1F1F;  
$FederationWriteColor:#FFFFFF;
$presidentDatasBgColor:#DF2935;
$managmentAndStuffColor:#FFFFFF;
$managmentAndStuffHoverBgColor:#DF2935;
$contactPageWriteColor:#FFFFFF;
$inpurErrorMessageColor:#DF2935;
$newsSectionWriteColor:#FFFFFF;
$homePageNewsWriteColor:#FFFFFF;
$ReadMoreNewsWriteColor:#FFFFFF; 
$FederationAboutHoverColor:#DF2935;
$MultimediaWriteColor:#FFFFFF;
$MultimediaHoverColor:#DF2935;












