.clubs-slide {
	.swiper {
		background-color: white;
		text-align: center;

		.swiper-slide {
			height: 8rem;

			img {
				height: 8rem;
				object-fit: cover;
			}
		}

		.swiper-button-prev,
		.swiper-button-next {
			display: none;
		}
	}
}