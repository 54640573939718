#athletesDetails {
	color: white;

	.header-img {
		width: 100%;
		height: 70vh;
		object-fit: cover;
	}

	.athletesDetailsWrapper {
		max-width: 80rem;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;

		.athletDetailsImg {
			width: 34rem;
			height: 38rem;
			object-fit: cover;
		}

		.athletDetailsInfo {
			width: 34rem;
			text-align: center;

			.athletDetailsTitle {
				font-size: 3rem;
			}

			.athletDetailsBirthday {
				display: block;
				margin: 7px 0;
				font-size: 1.4rem;
			}

			.athletDetailsDescription {
				font-size: 1.4rem;
				margin-top: 20px;
				white-space: pre-line;
				text-align: start;
			}
		}
	}
}

//  athletesDetails section responsive 900px style start
@media screen and (max-width: 900px) {
	#athletesDetails {
		.header-img {
			width: 100%;
			height: 40vh;
			object-fit: cover;
		}

		.athletesDetailsWrapper {
			display: flex;
			flex-direction: column;
			justify-content: inherit;
			align-items: center;

			.athletDetailsImg {
				width: 26rem;
				height: 30rem;
			}

			.athletDetailsInfo {
				width: 26rem;
				padding-top: 20px;

				.athletDetailsTitle {
					font-size: 1.7rem;
					text-align: center;
				}

				.athletDetailsDescription {
					margin-top: 10px;
				}
			}
		}
	}
}