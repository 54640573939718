@import "../../ListColorsUsedSite/colors.scss";


@mixin fl-btwn-centr {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin fl-concetr-centr {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin fl-clum {
	display: flex;
	flex-direction: column;
}

@mixin fl-btw {
	display: flex;
	justify-content: space-between;
}

@mixin fl-Alcentr {
	display: flex;
	align-items: center;
}

// ====  footer section default style  =========================
#footer-section {
	background-color: $footerBgColor;
	color: $footerColor;
	font-size: 1.2rem;
	font-weight: 400;

	.container {
		width: calc(100% - 40px);
		padding: 0;

		.footer {
			display: flex;
			align-items: center;
			text-align: center;
			height: 9rem;
			line-height: 2rem;
			gap: 2rem;
			justify-content: space-between;

			.web-logo {
				height: 5rem;
			}

			.mobil-logo {
				display: none;
			}

			.page-list {
				flex: .7;

				li {
					display: flex;
					flex-basis: 8rem;

					a {
						color: $footerColor;
						text-decoration: underline;
					}
				}

				.page-list-top {
					display: flex;
					gap: 1rem;
				}

				.page-list-bottom {
					display: flex;
					gap: 1rem;
				}
			}

			.address {
				flex: .6 1;
				margin: 0;
			}

			.call-info {
				flex: .5;

				a {
					color: $footerColor;
				}
			}

			.social {
				display: flex;
				flex: .3;

				a {
					color: $footerColor;
					margin: 0 .4rem;
				}
			}

			.prepared {
				.title {
					color: $footerColor;
					font-size: 15px;
				}

				.looptech-logo {
					width: 90px;
					pointer-events: none,
				}
			}
		}
	}
}


// ====  footer section responsive style  =========================

@media screen and (max-width: 1050px) {
	#footer-section {
		background-color: $MobilFooterBgColor;

		.container {
			.footer {
				height: auto;
				flex-direction: column;
				line-height: 0;
				gap: 0;

				.web-logo {
					display: none;
				}

				.mobil-logo {
					display: block;
					height: 10rem;
					padding-top: 2rem;
					object-fit: scale-down;
				}

				.page-list {
					display: none;
				}

				.address {
					display: none;
				}

				.call-info {
					display: flex;
					flex-direction: column;
					row-gap: 2.5rem;
					margin: 15px 0;

					a {
						color: white;
						font-size: 17px;
					}
				}

				.social {
					li {
						margin: 1rem .7rem 3rem;
					}

					svg {
						color: white;
						font-size: 3rem;
					}
				}

				.prepared {

					li {
						margin-bottom: 20px;
						color: white !important;
					}
				}
			}
		}
	}
}