@import "../../ListColorsUsedSite/colors.scss";


@mixin fl-btwn-centr {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin fl-concetr-centr {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin fl-clum {
	display: flex;
	flex-direction: column;
}

@mixin fl-btw {
	display: flex;
	justify-content: space-between;
}

@mixin fl-Alcentr {
	display: flex;
	align-items: center;
}

// === multimedaiSeeMore page default style ====
#multimedia-see-more {
	min-height: calc(100vh - 15rem);

	h4 {
		font-size: 2rem;
		font-weight: 700;
		color: white;
		text-align: center;
		width: 70%;
		margin: 0 auto;
		padding-top: 2rem;
	}

	.container {
		padding-top: 1.5rem;

		.see-more-images {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
			justify-content: center;
			justify-items: center;
			grid-gap: 2rem;

			img,
			a {
				position: relative;
				width: 100%;
				object-fit: cover;
				aspect-ratio: 4 / 3;
				cursor: pointer;
			}

			.playButton {
				position: absolute;
				top: 37%;
				left: 45%;
				width: 55px;
				height: 55px;
			}
		}
	}
}