@import "../../ListColorsUsedSite/colors.scss";

button {
	margin-top: 1rem;
	padding: 1.5rem 2rem;
	border: none;
	font-size: 1.5rem;
	font-weight: 700;
	cursor: pointer;
	background-color: $redButtonBG-color;
	color: $redButton-color;
}

// component button responsive
@media screen and (max-width: 1050px) {
	button {
		padding: 1rem 1.5rem;
		font-size: 1.3rem;
	}
}