@import "../../ListColorsUsedSite/colors.scss";

@mixin fl-btwn-centr {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin fl-concetr-centr {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin fl-clum {
	display: flex;
	flex-direction: column;
}

@mixin fl-btw {
	display: flex;
	justify-content: space-between;
}

@mixin fl-Alcentr {
	display: flex;
	align-items: center;
}


// events section default style start 
#events {
	.container {
		h2 {
			display: block;
			font-size: 4rem;
			font-weight: 700;
			text-align: center;
			color: white;
			margin-bottom: 4rem;
		}

		.table-mobile {
			display: none;
		}

		.table-web {
			background-color: $EventSectionTableBgColor;
			text-align: center;
			padding: 2rem 1rem;
			margin: 1rem auto 7rem;

			hr {
				width: 98%;
				margin: 1rem auto;
			}

			ul {
				display: flex;
				margin: 0 auto;
			}

			.event {
				flex: .3
			}

			.distance {
				flex: 1.5
			}

			.date {
				flex: 0.5
			}

			.table-date {
				display: flex;
				margin: 2rem auto;

				h5 {
					margin: 0;
					font-size: 1.2rem;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
				}
			}
		}
	}
}

//============== Events section responsive start 1050px  ======================
@media screen and (max-width: 1050px) {
	#events {
		.container {
			width: 95%;

			.table-web {
				display: none;
			}

			.table-mobile {
				display: grid;
				grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
				justify-content: center;
				justify-items: center;
				grid-gap: 2rem;

				.table {
					display: flex;
					justify-content: space-between;
					margin-bottom: 3rem;
					padding: 1rem;
					background-color: $ResultsSectionTableBgColor;

					ul {
						li {
							margin: 1rem auto;
						}
					}

					.table-date {
						display: flex;
						flex-direction: column;
						margin-left: 2rem;

						h5 {
							width: 150px;
							margin: 0.95rem 0;
							font-size: 1.2rem;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 1;
							overflow: hidden;
						}

						.date {
							margin-top: 1rem;
						}
					}
				}
			}

		}
	}
}