@import "../../ListColorsUsedSite/colors.scss";

@mixin fl-btwn-centr {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin fl-concetr-centr {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin fl-clum {
	display: flex;
	flex-direction: column;
}

@mixin fl-btw {
	display: flex;
	justify-content: space-between;
}

@mixin fl-Alcentr {
	display: flex;
	align-items: center;
}

// === LETS DO IT SECTION DEFAULT STYLE START
#lets-do-it {
	margin-top: 7rem;
	padding-bottom: 7rem;
	height: auto;
	color: $letsDoItSectionColor;

	.container {
		background-color: #EF3340;
		padding: 0;

		.registration-image {
			@include fl-btw();

			.registration {
				padding: 2rem 0 0 3rem;
                .homeRegister{
					font-size: 2.5rem;
					font-weight: 500;
					text-transform: uppercase;
					margin-bottom: 2rem;
					width: 70%;
					line-height: 3.2rem;
				}
				.red-btn {
					display: none;
				}
			}

			.image {
				position: relative;
				height: 23rem;
				background-image: url("../../assets/images/patternn.svg");
				width: 50%;
				background-repeat: no-repeat;
				background-size: cover;


				img {
					height: 27rem;
					position: absolute;
					transform: translate(-50%, -4%);
					top: 50%;
					left: 50%;
					animation-name: hareket;
					animation-duration: 1.9s;
					animation-iteration-count: infinite;
					animation-direction: alternate;
					animation-timing-function: linear;
				}
			}

		}
	}
}



//======== lets do it secton responsive ==============================

@media screen and (max-width: 1050px) {
	#lets-do-it {
		.container {
			background-color: transparent;

			.registration-image {
				flex-direction: column-reverse;
				align-items: center;

				.registration {
					text-align: center;
					margin-top: 3rem;
					padding: 0;
	     .homeRegister {
			font-size: 2rem;
			width: 100%;
			line-height: 3rem;
			margin: 0 auto;
		}

					.blue-btn {
						display: none;
					}

					.red-btn {
						display: block;
					}
				}

				.image {
					background-image: none;
				}

			}
		}
	}
}

@keyframes hareket {
	0% {
		top: 0;
	}

	100% {
		top: -5rem;
	}
}