@import "../../ListColorsUsedSite/colors.scss";

@mixin fl-btwn-centr {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin fl-concetr-centr {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin fl-clum {
	display: flex;
	flex-direction: column;
}

@mixin fl-btw {
	display: flex;
	justify-content: space-between;
}

@mixin fl-Alcentr {
	display: flex;
	align-items: center;
}

// ===== read more news section default style =====
#read-more-news {
	color: $ReadMoreNewsWriteColor;
	position: relative;

	.container {
		.tr-one-news {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
			justify-content: center;
			column-gap: 1rem;
			position: relative;

			.swiper {
				height: 100vh;
				position: sticky;
				top: -40px;
				z-index: 1;
				margin-top: -4rem;
			}

			.swiper-slide {
				overflow: hidden;
				margin-bottom: 0;

				img {
					object-fit: cover;
					aspect-ratio: 16 / 9;
					width: 100%;
				}
			}

			.read-news {
				margin-left: 4rem;
				text-align: start;

				h4 {
					font-size: 1.5rem;
					font-weight: 500;
					padding-right: 10rem;
				}

				span {
					display: inline-block;
					font-size: 1rem;
					font-weight: 300;
					margin: 1.5rem 0 2rem;
				}

				p {
					font-size: 1.4rem;
					text-align: start;
					white-space: pre-line;
					word-break: break-word;
				}
			}
		}

		.right {
			padding: 0;
			background-color: #D9D9D9;
			color: black;
			width: 3rem;
			height: 3rem;
			position: fixed;
			bottom: 10rem;
			right: 1.7rem;
			z-index: 5;

			.icon {
				font-size: 3rem;
				position: fixed;
				bottom: 10rem;
				right: 1.7rem;
			}
		}

		.left {
			padding: 0;
			background-color: #D9D9D9;
			color: black;
			width: 3rem;
			height: 3rem;
			position: fixed;
			bottom: 10rem;
			left: 1.7rem;
			z-index: 5;

			.icon {
				font-size: 3rem;
				position: fixed;
				bottom: 10rem;
				left: 1.7rem;
			}
		}
	}
}


// ===== read more news section responsive 1050px  style =====
@media screen And (max-width:1050px) {
	#read-more-news {
		// background-color: $BgColorBlue;

		.container {
			padding-top: 1rem;

			.tr-one-news {
				display: flex;
				flex-direction: column;

				.swiper {
					margin-top: 0;
					height: auto;
					position: initial;
					max-width: 100%;
				}

				.swiper-slide {
					overflow: hidden;
					margin-bottom: 0;

					img {
						object-fit: cover;
						aspect-ratio: 16 / 9;
						width: 100%;
						max-width: 100vw;
					}
				}

				.read-news {
					margin-top: 2rem;
					margin-left: 0;

					h4 {
						padding: 0;
					}
				}
			}
		}
	}
}