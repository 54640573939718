@import "../../ListColorsUsedSite/colors.scss";

@mixin fl-btwn-centr {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin fl-concetr-centr {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin fl-clum {
	display: flex;
	flex-direction: column;
}

@mixin fl-btw {
	display: flex;
	justify-content: space-between;
}

@mixin fl-Alcentr {
	display: flex;
	align-items: center;
}

// ===  Home page news default style start
#home-news {
	color: $homePageNewsWriteColor;

	.container {
		.home-page-news {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
			justify-content: center;
			justify-items: center;
			grid-gap: 2rem;

			.home-news {
				width: 100%;
				position: relative;
				cursor: pointer;
				color: #fff;

				img {
					width: 100%;
					height: 19rem;
					object-fit: cover;
					filter: grayscale(100%);
				}

				&:hover img {
					filter: grayscale(0);
					transition: filter 0.3s ease;
				}

				.HomeNewsMiniInfo {
					padding: 0 1rem;

					h4 {
						font-size: 1.4rem;
						font-weight: 500;
						margin-top: 2rem;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					p {
						font-size: 1.2rem;
						font-weight: 500;
						margin: 1.5rem 0 2rem;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					span {
						font-size: 1.3rem;
						font-weight: 600;
						text-decoration: underline;
						color: white;
					}
				}
			}
		}

		.btn-link {
			display: block;
			text-align: center;
			margin: 3rem auto;
			padding: 1.4rem 0.5px;
			border: none;
			font-size: 1.5rem;
			font-weight: 600;
			cursor: pointer;
			background-color: $redButtonBG-color;
			color: $redButton-color;
			width: 15rem;
		}
	}
}

// ===  Home page news response  style start
@media (max-width:1050px) {
	#home-news {
		.container {
			.home-page-news {
				.home-news {

					img {

						filter: grayscale(0);
					}
				}
			}
		}
	}
}

@media (max-width:622px) {
	#home-news {
		.container {
			.home-page-news {
				.home-news {
					.HomeNewsMiniInfo {
						text-align: center;
					}
				}
			}

		}
	}
}